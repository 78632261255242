import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

export const userRegister = createAsyncThunk('user/userRegister', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/users/signup', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const userLogin = createAsyncThunk('user/userLogin', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/users/signin', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const whoami = createAsyncThunk('user/whoami', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get('/users/whoami');
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const updateInfo = createAsyncThunk('user/updateInfo', async (data, { rejectWithValue }) => {
  try {
    const response = await api.put('/users/update', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const changePassword = createAsyncThunk('user/changePassword', async (data, { rejectWithValue }) => {
  try {
    const response = await api.put('/users/password', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const getSysStatus = createAsyncThunk('user/getSysStatus', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get('/status', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const getAgentData = createAsyncThunk('user/getAgentData', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get('/users/agent', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const updateByAgent = createAsyncThunk('user/updateByAgent', async (data, { rejectWithValue }) => {
  try {
    const response = await api.put('/users/agent', data);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const getBetData = createAsyncThunk('user/getBetData', async (data, { rejectWithValue }) => {
  try {
    const response = await api.get(`/users/bet?username=${data}`);
    return response?.data;
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

const UserSlice = createSlice({
  name: 'user',
  initialState: {
    accessToken: '',
    user: {},
    bet: [],
    agent: [],
    sys: {
      all: true,
      bet: true,
      deposit: true,
      withdraw: true,
      register: true,
      forgot: true,
      minDeposit: 200000,
      minWithdraw: 200000,
    },
    isLoading: false,
  },
  reducers: {
    logo2ut: (state) => {
      state.accessToken = '';
      state.user = null;
      state.bet = [];
    },
    pushBetOpen: (state, action) => {
      state.bet.push(action.payload);
    },
    clearBetOpen: (state, action) => {
      state.bet = [];
    },
    setUserData: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [userRegister.pending]: (state, action) => {
      state.isLoading = true;
    },
    [userRegister.fulfilled]: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
      state.isLoading = false;
    },
    [userRegister.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [userLogin.pending]: (state, action) => {
      state.isLoading = true;
    },
    [userLogin.fulfilled]: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
      state.isLoading = false;
    },
    [userLogin.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [whoami.pending]: (state, action) => {
      state.isLoading = true;
    },
    [whoami.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    [whoami.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getSysStatus.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getSysStatus.fulfilled]: (state, action) => {
      state.sys = action.payload;
      state.isLoading = false;
    },
    [getSysStatus.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getAgentData.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAgentData.fulfilled]: (state, action) => {
      state.agent = action.payload;
      state.isLoading = false;
    },
    [getAgentData.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [updateInfo.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateInfo.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    [updateInfo.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default UserSlice.reducer;
export const { logo2ut, pushBetOpen, clearBetOpen, setUserData, setSessionState } = UserSlice.actions;
