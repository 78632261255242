import { Popover, Transition } from '@headlessui/react';
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  ChartBarIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  HomeIcon,
  UserGroupIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Dropdown, Layout, Menu, Modal, Select, Space } from 'antd';
import { Fragment, useEffect, useState, useTransition } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { getSysStatus, logo2ut } from '../features/user/userSlice';
import { USER_ROLE } from '../utils/constants';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
const MainLayout = () => {
  const { Header, Content } = Layout;
  const user = useSelector((state) => state.user);
  const sys = useSelector((state) => state.user.sys);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuItem, setMenuItem] = useState([]);
  const { t } = useTranslation();

  const onlogo2ut = () => {
    dispatch(logo2ut());
    window.location.href = '/';
  };

  const fetchSys = async () => {
    await dispatch(getSysStatus());
  };

  useEffect(() => {
    fetchSys();
  }, []);

  useEffect(() => {
    if (user.accessToken === '') {
      setMenuItem([]);
    } else {
      setMenuItem([
        {
          name: <span>{t('trade')}</span>,
          href: '/trade',
          icon: ChartBarIcon,
        },
        {
          name: <span>{t('user_center')}</span>,
          href: '/user_center',
          icon: UserIcon,
        },
      ]);
    }
  }, [user]);

  const usernameMenu = (
    <Menu
      onClick={(e) => {
        if (e.key === 'logo2ut') {
          onlogo2ut();
        }
      }}
      items={[
        {
          label: <span>{t('logo2ut')}</span>,
          key: 'logo2ut',
        },
      ]}
    />
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const language = localStorage.getItem('language');

  useEffect(() => {
    if (!language) {
      localStorage.setItem('language', 'vi');
      window.location.reload();
    }
  }, []);

  return (
    <>
      <Layout>
        <Header>
          <Popover>
            <div className='mx-auto max-w-7xl px-4 sm:px-6'>
              <div className='flex items-center justify-between border-b-1 border-gray-100 lg:space-x-10'>
                <div className='flex '>
                  <a href='/'>
                    {/* <span className='sr-only'>BOTRADE</span> */}
                    <img src={require('../assets/images/logoNoText.png')} alt='' style={{height:"40px",width:"auto"}}/>
                    {/* <span>BOTRADE</span> */}
                  </a>
                </div>
                <div className='-my-2 -mr-2 lg:hidden pt-4'>
                  <Popover.Button className='inline-flex items-center justify-center rounded-lg p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                    <span className='sr-only'>Open menu</span>
                    <Bars3Icon className='h-6 w-6' aria-hidden='true' />
                  </Popover.Button>
                </div>
                <Popover.Group as='nav' className='hidden space-x-6 lg:flex'>
                  {menuItem &&
                    menuItem.map((item, index) => (
                      <a key={index} href={item.href} className='text-base font-bold text-[#fff] hover:text-white'>
                        {item.name}
                      </a>
                    ))}

                  {user.user?.role === USER_ROLE.AGENT && (
                    <a href='/agent' className='hover:cursor-pointer text-base font-bold text-[#fff] hover:text-white'>
                      {t('agent')}
                    </a>
                  )}

                  {user.accessToken &&
                    (sys.telegram === '' ? (
                      <button
                        onClick={() => setIsModalOpen(true)}
                        className='hover:cursor-pointer text-base font-bold text-[#fff] hover:text-white'
                      >
                        <span>{t('support')}</span>
                      </button>
                    ) : (
                      <a
                        className='hover:cursor-pointer text-base font-bold text-[#fff] hover:text-white'
                        href={sys?.telegram}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span>{t('support')}</span>
                      </a>
                    ))}

                  {user.accessToken !== '' && user.user?.role !== USER_ROLE.USER && (
                    <button
                      onClick={() => navigate('/agent')}
                      className='hover:cursor-pointer text-base font-bold text-[#fff] hover:text-white'
                    >
                      <span>Quản lý</span>
                    </button>
                  )}

                  <Modal
                    title={<span>{t('support')}</span>}
                    open={isModalOpen}
                    okButtonProps={{ style: { display: 'none' } }}
                    cancelText={<span>{t('close')}</span>}
                    cancelButtonProps={{ style: { backgroundColor: 'red', color: '#fff' } }}
                    onCancel={() => setIsModalOpen(false)}
                  >
                    <p>{t('please_contact_agent')}</p>
                  </Modal>
                </Popover.Group>
                {user.accessToken !== '' ? (
                  <div className='hidden lg:block'>
                    {/* <div> */}
                    <Dropdown trigger={['click']} overlay={usernameMenu}>
                      <Space>
                        <span className='hover:text-white hover:cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-full bg-[#3d3d3d] border border-transparent px-3 py-1 text-base font-medium text-white shadow-sm'>
                          {user.user?.username}
                          <DownOutlined className='ml-2 ' />
                        </span>
                      </Space>
                    </Dropdown>
                  </div>
                ) : (
                  <div className='hidden items-center justify-end lg:flex lg:flex-1 lg:w-0'>
                    <Link
                      to='/login'
                      className='hover:text-white ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-full border border-transparent bg-[#3d3d3d] px-6 py-1 text-base font-medium text-white shadow-sm'
                    >
                      {t('join_now')}
                    </Link>
                  </div>
                )}

                <div className='hidden lg:block'>
                  <Select
                    defaultValue={language}
                    style={{ width: 120 }}
                    onChange={(e) => {
                      localStorage.setItem('language', e);
                      window.location.reload();
                    }}
                  >
                    <Select.Option value='vi'>Tiếng Việt</Select.Option>
                    <Select.Option value='en'>English</Select.Option>
                  </Select>
                </div>
              </div>
            </div>

            <Transition
              as={Fragment}
              enter='duration-200 ease-out'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='duration-100 ease-in'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Popover.Panel
                focus
                className='z-50 absolute inset-x-0 top-0 origin-top-right transform p-2 transition lg:hidden'
              >
                <div className='divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className='px-5 pt-5 pb-6'>
                    <div className='flex items-center justify-between'>
                      <div>
                        {/* <img width={80} src={require('../assets/images/logoNoText.png')} alt='logo2' />
                         */}
                        <span>BOTRADE</span>
                      </div>
                      <div className='-mr-2'>
                        <Popover.Button className='inline-flex items-center justify-center rounded-lg bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                          <span className='sr-only'>Close menu</span>
                          <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className='mt-6'>
                      <nav className='grid gap-y-8'>
                        {user.accessToken && (
                          <span className='hover:text-white hover:cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-full bg-[#3d3d3d] border border-transparent px-6 py-1 text-base font-medium text-white shadow-sm'>
                            {user.user?.username}
                          </span>
                        )}
                        {menuItem &&
                          menuItem.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className='-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50'
                            >
                              <item.icon className='h-6 w-6 flex-shrink-0 text-[#3d3d3d]' aria-hidden='true' />
                              <span className='ml-3 text-base font-medium text-[#02142b]'>{item.name}</span>
                            </a>
                          ))}

                        {user.user?.role === USER_ROLE.AGENT && (
                          <a href='/agent' className='-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50'>
                            <UserGroupIcon className='h-6 w-6 flex-shrink-0 text-[#3d3d3d]' aria-hidden='true' />
                            <span className='ml-3 text-base font-medium text-[#02142b]'>{t('agent')}</span>
                          </a>
                        )}

                        {user.accessToken &&
                          (sys.telegram === '' ? (
                            <span
                              onClick={() => setIsModalOpen(true)}
                              className='-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50'
                            >
                              <ChatBubbleOvalLeftEllipsisIcon
                                className='h-6 w-6 flex-shrink-0 text-[#3d3d3d]'
                                aria-hidden='true'
                              />
                              <span className='ml-3 text-base font-medium text-[#02142b]'>Chăm sóc khách hàng</span>
                            </span>
                          ) : (
                            <a
                              href={sys?.telegram}
                              target='_blank'
                              className='-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50'
                            >
                              <ChatBubbleOvalLeftEllipsisIcon
                                className='h-6 w-6 flex-shrink-0 text-[#3d3d3d]'
                                aria-hidden='true'
                              />
                              <span className='ml-3 text-base font-medium text-[#02142b]'>Chăm sóc khách hàng</span>
                            </a>
                          ))}

                        {user.accessToken !== '' ? (
                          <a
                            onClick={() => onlogo2ut()}
                            className='-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50'
                          >
                            <ArrowLeftOnRectangleIcon
                              className='h-6 w-6 flex-shrink-0 text-[#02142b]'
                              aria-hidden='true'
                            />
                            <span className='ml-3 text-base font-medium text-[#02142b]'>{t('logo2ut')}</span>
                          </a>
                        ) : (
                          <a href={'/login'} className='-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50'>
                            <ArrowLeftOnRectangleIcon
                              className='h-6 w-6 flex-shrink-0 text-[#02142b]'
                              aria-hidden='true'
                            />
                            <span className='ml-3 text-base font-medium text-[#02142b]'>{t('join_now')}</span>
                          </a>
                        )}

                        <div className='flex items-center'>
                          <span className='pr-4'>{language === 'vi' ? 'Ngôn ngữ' : 'Language'}: </span>
                          <Select
                            defaultValue={language}
                            style={{ width: 120 }}
                            onChange={(e) => {
                              localStorage.setItem('language', e);
                              window.location.reload();
                            }}
                          >
                            <Select.Option value='vi'>Tiếng Việt</Select.Option>
                            <Select.Option value='en'>English</Select.Option>
                          </Select>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </Header>
        <Content>
          <Outlet />
        </Content>
        {/* <Footer>© 2024 TEAM INTERNATIONAL ALL RIGHTS RESERVED</Footer> */}
      </Layout>
    </>
  );
};

export default MainLayout;
