import { unwrapResult } from '@reduxjs/toolkit';
import { Checkbox, Divider, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getSysStatus, userRegister } from '../features/user/userSlice';
import { t } from 'i18next';
const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State
  const [terms, setTerms] = useState(true);
  const [fullname, setFullname] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');

  // Ref query params
  const [searchParams] = useSearchParams();
  const refQuery = searchParams.get('ref');

  // Global state
  const { user, accessToken, sys } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getSysStatus());
  }, []);

  if (accessToken && user) {
    navigate('/trade');
  }

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    if (!terms) {
      message.error(`${t('error_term')}`);
      return;
    }

    if (!fullname || !phone || !username || !password || !repassword) {
      message.error('Vui lòng nhập đầy đủ thông tin');
      return;
    }

    if (password !== repassword) {
      message.error(`${t('password_not_match')}`);
      return;
    }

    try {
      if (refQuery) {
        const data = await dispatch(userRegister({ fullname, phone, username, password, ref: refQuery })).then(
          unwrapResult,
        );
        if (data) {
          message.success(`${t('register_success')}`);
          navigate('/trade');
        }
      } else {
        const data = await dispatch(userRegister({ fullname, phone, username, password })).then(unwrapResult);
        if (data) {
          message.success(`${t('register_success')}`);
          navigate('/trade');
        }
      }
    } catch (error) {
      message.error(error);
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [showPassword2, setShowPassword2] = useState(false);
  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  return (
    <div className='login_bg md:py-12 md:px-2 md:px-0'>
      <div className='flex h-full justify-center items-center'>
        <div className='card bg-[#313135] w-full md:w-[700px] pb-5 rounded-md m-3'>
          <div>
            <img
              src={require('../assets/images/logoNoText.png')}
              alt='logo2'
              className='mx-auto mt-6'
              style={{ maxWidth: '170px' }}
            />
            <p className='text-center text-white font-bold text-2xl mt-6' style={{ color: '#48a2cc' }}>
              {t('register11')}
            </p>

            {/*<div className='text-center mx-6 text-white text-base'>
              <span>{t('register1')}</span>
            </div>*/}
          </div>

          <div className='mx-3 md:mx-6 mt-10'>
            <div className='md:flex'>
              <div className='md:w-1/2 mx-2 mb-4'>
                <span className='text-white'>{t('fullname')}</span>
                <input
                  type='text'
                  name='fullname'
                  className='w-full h-10 rounded-xl mt-1 px-2'
                  placeholder={t('fullname')}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>

              <div className='md:w-1/2 mx-2 mb-4'>
                <span className='text-white'>{t('phone')}</span>
                <input
                  type='text'
                  name='phone'
                  className='w-full h-10 rounded-xl mt-1 px-2'
                  placeholder={t('phone')}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <div className='mx-2'>
              <div className=' mb-4'>
                <span className='text-white'>{t('username')} (viết liền không dấu)</span>
                <input
                  type='text'
                  name='username'
                  className='w-full h-10 rounded-xl mt-1 px-2'
                  placeholder={t('username')}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>

            <div className='md:flex md:mt-6'>
              <div className='md:w-1/2 mx-2 mb-4 relative'>
                <span className='text-white'>
                  {t('password')}
                  <nav></nav>
                </span>
                <input
                  name='password'
                  className='w-full h-10 rounded-xl mt-1 px-2'
                  placeholder={t('password')}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div onClick={toggleShowPassword} className='toggle_password'>
                  {!showPassword ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z'
                      />
                      <path stroke-linecap='round' stroke-linejoin='round' d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z' />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88'
                      />
                    </svg>
                  )}
                </div>
              </div>

              <div className='md:w-1/2 mx-2 mb-4 relative'>
                <span className='text-white'>{t('confirm_password')}</span>
                <input
                  name='retype_password'
                  className='w-full h-10 rounded-xl mt-1 px-2'
                  placeholder={t('confirm_password')}
                  type={showPassword2 ? 'text' : 'password'}
                  onChange={(e) => setRepassword(e.target.value)}
                />
                <div onClick={toggleShowPassword2} className='toggle_password'>
                  {!showPassword2 ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z'
                      />
                      <path stroke-linecap='round' stroke-linejoin='round' d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z' />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88'
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>

            <div className='mt-6'>
              <Checkbox defaultChecked={terms} onChange={setTerms}>
                <span className='text-white text-base'>
                  {t('register2')}
                  {/*<Link className='text-[#ffeb3c]' to={'/policy'}>
                    {t('register3')}
                  </Link>*/}
                </span>
              </Checkbox>
            </div>

            <div className='text-center'>
              <button
                disabled={!sys.register}
                className='mt-10 bg-[#02142b] py-2 rounded-lg px-6 text-white font-semibold'
                onClick={(e) => onHandleSubmit(e)}
              >
                {sys.register ? `${t('register')}` : 'ĐĂNG KÝ TẠM THỜI ĐÓNG'}
              </button>
            </div>

            <div className='h-1'>
              <Divider className='bg-white' />
            </div>

            <div className='flex justify-around'>
              <button className='py-2 rounded-lg px-6 text-white text-base'>{t('support')}</button>
              <Link to={'/login'}>
                <button className='py-2 rounded-lg px-6 text-white text-base'>{t('login')}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
