import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { t } from 'i18next';
const ShowDateTime = () => {
  const [datetime, setDatetime] = useState(moment().format('DD/MM/YYYY HH:mm:ss'));

  useEffect(() => {
    const a = setInterval(() => {
      setDatetime(moment().format('DD/MM/YYYY HH:mm:ss'));
    }, 1000);

    return () => {
      clearInterval(a);
    };
  }, []);

  return (
    <div
      className='flex ml-0 md:ml-4 bg-[#011021] items-center justify-center md:justify-start h-12 relative'
      style={{ maxWidth: '450px' }}
    >
      <img className='hidden md:block w-10' src={require('../assets/images/loading.gif')} alt='time' />
      <span className='text-white text-sm font-bold mr-1 hidden md:block'>{t('time')}: </span>
      <span className='text-white text-sm'>{datetime}</span>
      <div className='absolute' style={{ right: '1rem', top: '50%', transform: 'translateY(-50%)', cursor: "pointer" }} onClick={() => window.location.reload()}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#fff' style={{ height: '20px' }}>
          <path
            fill-rule='evenodd'
            d='M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z'
            clip-rule='evenodd'
          />
        </svg>
      </div>
    </div>
  );
};

export default ShowDateTime;
