import React from 'react';
import { useSelector } from 'react-redux';
import Sidebar from '../components/Sidebar';
import Chart from './Chart';

const UserCenter = () => {
  const { accessToken } = useSelector((state) => state.user);

  if (accessToken === '') {
    window.location.href = '/login';
  }

  return (
    <div className='container-bg w-full'>
      <div className='flexs'>
        <Sidebar />
        <div className='w-screen hidden md:block'>
          <div className='flex'>
            {/* <div className='lg:w-3/12 bg-[#02142b] lg:mx-1 mx-0 mb-10 rounded-md'>
              <Card title={t('trade')}>
                <SidePriceItem />
              </Card>
            </div> */}

            <div className='w-full'>
              <Chart />
            </div>

            {/* <div className='lg:w-3/12 bg-[#02142b] lg:mx-1 mx-0 mb-4 md:rounded-md rounded-none z-10 min-h-[200px]'>
              <Card title={t('my_trade')}>
                <SideUserTransaction />
              </Card>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCenter;
