import React, { useState } from 'react';
import { Divider, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSysStatus, userLogin } from '../features/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { t } from 'i18next';
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSysStatus());
  }, []);

  const { accessToken, user, sys } = useSelector((state) => state.user);

  if (accessToken && user) {
    navigate('/trade');
  }

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      message.error('Please fill all fields');
      return;
    }

    try {
      await dispatch(userLogin({ username, password })).then(unwrapResult);
      message.success(`${t('login_success')}`);
      navigate('/trade');
    } catch (error) {
      message.error(error);
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className='login_bg md:py-12 md:px-2 md:px-0'>
      <div className='flex h-full justify-center items-center '>
        <div className='card bg-[#313135] w-full md:w-[700px] pb-5 rounded-md m-3'>
          <div>
            <img
              src={require('../assets/images/logoNoText.png')}
              alt='logo2'
              className='mx-auto mt-6'
              style={{ maxWidth: '170px' }}
            />
            <p className='text-center text-white font-bold text-2xl mt-6' style={{ color: '#48a2cc' }}>
              {t('login')}
            </p>
          </div>

          <div className='mx-6'>
            <div>
              <span className='text-white'>{t('username')} (viết liền không dấu)</span>
              <input
                type='text'
                className='w-full h-10 rounded-xl mt-2 px-2'
                placeholder={t('username')}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className='mt-4 relative'>
              <span className='text-white'>{t('password')}</span>
              <input
                className='w-full h-10 rounded-xl mt-2 px-2'
                placeholder={t('password')}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div onClick={toggleShowPassword} className='toggle_password'>
                {!showPassword ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      d='M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z'
                    />
                    <path stroke-linecap='round' stroke-linejoin='round' d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z' />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      d='M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88'
                    />
                  </svg>
                )}
              </div>
            </div>
            <div className='text-center'>
              <button
                className='mt-10  bg-[#02142b] py-2 rounded-lg px-6 text-white font-semibold'
                onClick={(e) => onHandleSubmit(e)}
              >
                {t('login')}
              </button>
            </div>

            <div className='h-1'>
              <Divider className='bg-white' />
            </div>

            <div className='flex justify-around'>
              {sys.forgot && (
                <button className='py-2 rounded-lg px-6 text-white text-base'>{t('forgot_password')}</button>
              )}
              <Link to='/register'>
                <button className='py-2 rounded-lg px-6 text-white text-base'>{t('register')}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
